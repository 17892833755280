@use 'src/src/app/scss/abstracts' as *;

.confirm-age-modal {
  $block-name: &;

  //   #{$block-name} {
  //   }

  @include scrollbar;
  @include reset-text-indents;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: fixed;
  z-index: $z-index-confirm-age;
  width: 100%;
  height: 100%;
  transform: translate3d(0, -100%, 0);
  transition: transform $transition-slow;
  inset: 0;
  background: linear-gradient(180deg, rgb(0 0 0 / 90%) 0%, #6100ff 100%);
  overscroll-behavior: contain;
  overflow-y: auto;
  padding-top: 40px;
  padding-bottom: 40px;

  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
  }

  &__button-no {
    display: inline-flex;
    margin: -5px;
    padding: 5px;
    color: rgba($color-semantic-third, 0.85);
    text-decoration: none;
    font-size: 24px;
    font-weight: $font-weight-m;
    line-height: 120%;
    letter-spacing: -0.48px;
    transition: color $transition-fast;

    &:hover {
      color: $color-base-white-100;
    }

    &:active {
      color: rgba($color-semantic-third, 0.85);
    }
  }

  &__button-yes {
    margin-bottom: 70px;

    @include mq($until: md) {
      margin-bottom: 40px;
    }
  }

  &__description {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 30px;

    .title {
      line-height: 0.9;
    }
  }

  &__icon {
    margin-bottom: 25px;

    svg {
      @include mq($until: md) {
        width: 100px;
        height: 110px;
      }
    }
  }

  &--show {
    transform: translate3d(0, 0, 0);
  }

  &--hide {
    transform: translate3d(0, 100%, 0);
  }
}
