@mixin scrollbar() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;

    // width: 5px;
    // height: 5px;
    // transition: background 0.3s;
  }

  ::-webkit-scrollbar-track {
    // background: rgba(0, 0, 0, 0.1);
    // border: 1px solid #dcdcdc;
  }

  ::-webkit-scrollbar-thumb {
    // background: hsl(240deg 4% 91%);
    // border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    // background: #c1c1c1;
  }

  ::-webkit-scrollbar-thumb:active {
  }
}
